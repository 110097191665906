import React, { Component } from 'react';
import { GatsbyImage } from "gatsby-plugin-image"
import Glide from '@glidejs/glide'

import "@glidejs/glide/dist/css/glide.core.min.css"
import "./css/slider.css"

import ArrowLeft from "../images/icons/left-arrow.svg";
import ArrowRight from "../images/icons/right-arrow.svg";

const isBrowser = typeof window !== "undefined";

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliders: [],
      resizeObserver: null,
      sliderDuration: 3000,
    };
  }

  componentDidMount() {
    if (isBrowser) {
      let t = this;
      let images = t.props.images

      if (images.length > 1) {
        var sliders = [];
        document.querySelectorAll('.glide').forEach(function (glide) {
          if (!glide.classList.contains('glide--slider')) {
            let g = new Glide(glide, { gap: 0, autoplay: t.state.sliderDuration })
            g.mount()
            sliders.push(g)
          }
        });

        let resizeObserver = new ResizeObserver(entries => {
          t.updateSlides();
        });

        t.setState({
          sliders: sliders,
          resizeObserver: resizeObserver,
        }, () => {
          this.state.resizeObserver.observe(document.scrollingElement);
        });
      }
    }
  }

  componentWillUnmount() {
    if (isBrowser) {
      if (this.state.resizeObserver !== null) {
        this.state.resizeObserver.disconnect();
      }

      this.state.sliders.forEach(function (glide) {
        glide.destroy()
      });

      this.setState({
        sliders: [],
        resizeObserver: null,
      });
    }
  }

  updateSlides() {
    if (isBrowser) {
      this.state.sliders.forEach(function (glide) {
        glide.update()
      });
    }
  }

  render() {
    let slider = [];
    let images = this.props.images
    let hideArrow = this.props.hideArrows

    if (images.constructor !== Array) {
      images = [images]
    }

    slider = images.map((image, index) => {
      if (image.childImageSharp === null) {
        return null
      }
      return (
        <li key={index} className="glide__slide">
          <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={"Slide " + index} />
        </li>
      )
    });

    return (
      <div className="glide">
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {slider}
          </ul>
        </div>

        {!hideArrow && images.length > 1 &&
          <div className="glide__arrows" data-glide-el="controls">
            <button className="glide__arrow glide__arrow--left" data-glide-dir="<">
              <span>Previous image</span>
              <ArrowLeft />
            </button>
            <button className="glide__arrow glide__arrow--right" data-glide-dir=">">
              <span>Next image</span>
              <ArrowRight />
            </button>
          </div>
        }

      </div>
    );
  }
}

export default Slider;
